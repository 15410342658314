import React from 'react';
import styles from './NotFoundPage.module.css';


const NotFoundPage = () => {

    return (
        <div className={styles.NotFound}>
            Not Found
            <br/>
            404
        </div>

    );
};

export default NotFoundPage;
